import React, { useEffect, useState } from 'react'
import SEO from '../../components/seo'
import Layout from '../../components/layout'
import HowItWorkBlock from '../../components/Blocks/HowItWorkBlock'
import SectionBoxReady from '../../components/Platfrom/SectionBoxReady'
import { DataSeverTaggingHowItWork, DataSeverSideDestinations } from '../../../src/constants.js'
import RelatedPlatformBlock from '../../components/Blocks/related-block/RelatedPlatformBlock'
import SectionBoxCardText from '../../components/Platfrom/SectionBoxCardText'
import { FaqAccordionBlock } from '../../components/Blocks/AccordionBlock'
import BreadcrumbSection from '../../components/breadcrumb'
import PopupSchedule from '../../components/popupSchedule/PopupSchedule'

const dataCardText = [
     {
          title: 'HIPAA Compliant Analytics & Conversion Tracking',
          desc: `ListenLayer offers a powerful, HIPAA compliant analytics solution that allows you to track and report on website users, and event distribute conversion data to marketing platforms, while remaining compliant. Our powerful server-side tagging enables the control required.`,
          learnMore: 'Learn more >',
          url: '/platform/hippa/',
     },
     {
          title: `Don't Do This Alone`,
          desc: 'We offer implementation and managed services to help you succeed, or rely on our automated onboarding and world-class support.',
     },
]

const DataFAQs = [
     {
          cfFaq: {
               faqAnswer: `<p>ListenLayer can operate as your single source of truth, both for generating data and distributing it to other locations. Once you define critical actions, like conversions, inside ListenLayer, you can choose to send that data anywhere.  The data can be sent from the website visitor's browser (client-side), which requires multiple calls per event.  An alternative is to implement server-side tagging. The event is collected once from the user's browser, and can even happen over your own domain name.  Then it's taken into "the cloud" and distributed to other services (like Google Ads, GA4, Facebook Ads, LinkedIn Ads, etc) "server-side."</p>\n`,
               faqQuestion: 'How does server-side tracking work?',
          },
     },
     {
          cfFaq: {
               faqAnswer: `<p>Server-side tagging allows you to remove many 3rd party scripts and data collection from your website, reducing load times and improving the visitor's experience. In addition, data collection is more reliable over server-side because there are fewer variables that "can go wrong" when collecting the data! This can oftentimes result in much more accurate data. </p>\n`,
               faqQuestion: 'What are the benefits of server-side tagging?',
          },
     },
     {
          cfFaq: {
               faqAnswer: `<p>Many services do not support server-side tagging without implementing workarounds.  For those that are possible, we're building integrations now!  But our existing server-side tagging covers 95%+ of website analytics activity!</p>\n`,
               faqQuestion: 'Why are only some destinations offered server-side?',
          },
     },
     {
          cfFaq: {
               faqAnswer: `<p>Most analytics solutions load resources and collect data over their own domain, making them "3rd party."  ListenLayer allows you to create a custom subdomain on your own website so you can load and transmit data in a first-party context.  This can improve speed, trust, and reliability - especially if you use ListenLayer's server-side tagging to remove the need for other analytics tools to run client-side.</p>\n`,
               faqQuestion: 'What is 100% first-party tracking?',
          },
     },
]

const pageName = 'Server Side Tagging'
const breadcrumbItems = [
     {
          name: 'Platform',
          url: `${'platform'}`,
     },
     {
          name: `${pageName}`,
     },
]

const ServerSideTagging = ({ location }) => {
     const canonical = process.env.GATSBY_SITE_URL + location.pathname

     const [showSchedule, setShowSchedule] = useState(false)

     useEffect(() => {
          if (showSchedule) {
               document.querySelector('html').setAttribute('style', 'overflow:hidden')
          } else {
               document.querySelector('html').setAttribute('style', 'overflow:initial')
          }
     }, [showSchedule])

     useEffect(() => {
          if (window.location.href.includes('view-demo=1')) {
               setShowSchedule(true)
          }
     }, [])

     return (
          <Layout>
               <SEO classBody="sever-tagging" canonical={canonical} />
               <PopupSchedule showSchedule={showSchedule} setShowSchedule={setShowSchedule} />
               <BreadcrumbSection breadcrumbItems={breadcrumbItems} />
               {/* section 1 */}
               <section className="platform-intro">
                    <div className="container">
                         <div className="platform-intro-title">
                              <h1>
                                   Server-side Tagging <br />
                                   That's Easy
                              </h1>
                              <p>Improve your website's speed and data reliability with server-side tagging that's easy to implement and manage.</p>
                         </div>
                    </div>
               </section>
               <section className="automate-web">
                    <div className="container">
                         <HowItWorkBlock
                              firstContent={DataSeverTaggingHowItWork.firstContent}
                              actions={DataSeverTaggingHowItWork.actions}
                              secondContent={DataSeverTaggingHowItWork.secondContent}
                         />
                    </div>
               </section>
               <SectionBoxReady />
               <section className="server-side-destinations">
                    <div className="container">
                         <RelatedPlatformBlock titleH3="Popular Server-side Destinations" relatedPlatforms={DataSeverSideDestinations} />
                    </div>
               </section>
               <SectionBoxCardText data={dataCardText} />
               <section className="server-side-faq">
                    <div className="container">
                         <FaqAccordionBlock faqs={DataFAQs} buttonText={null} headline="Frequently Asked Questions" />
                    </div>
               </section>
          </Layout>
     )
}

export default ServerSideTagging
